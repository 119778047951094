import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const Datenschutzerklaerung = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/datenschutzerklaerung")
    }
  }, [])

  const handleReject = () => {
    localStorage.setItem("cookiesAccepted", false)
    const wiredminds = document.getElementById("wm")
    const linkedinScript = document.getElementById("linkedin-insight");

    if (linkedinScript) {
      linkedinScript.parentNode.removeChild(linkedinScript);
    }

    if (wiredminds && wiredminds.parentNode) {
      window["wiredminds"].optOut()
      wiredminds.parentNode.removeChild(wiredminds)
    }

    alert("Ihre Besuche werden nun nicht mehr erfasst.")
  }

  return (
    <React.Fragment>
      <PageTitle title={"Datenschutzerklärung"} />
      <Layout>
        <div className="imprint-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Datenschutzerklärung</a>
              </li>
            </ul>
          </div>
          <div className="data-protection">
            <h1>Datenschutzerklärung</h1>
            <div className="data-protection__block">
              <div className="text">
                <p>
                  Der Schutz Ihrer Daten ist der Cyber Trust Services GmbH (in
                  weiterer Folge „CTS“) ein wichtiges Anliegen. Wir verarbeiten
                  Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen
                  Bestimmungen gemäß der EU-Datenschutz-Grundverordnung (DSGVO).
                  In dieser Datenschutzerklärung informieren wir Sie über die
                  wichtigsten Aspekte der Datenverarbeitung im Rahmen unseres
                  Unternehmens.
                </p>
                <div className="name">Verantwortlichkeiten</div>
                <p>
                  Verantwortlicher gemäß Artikel 4 Abs 7
                  EU-Datenschutz-Grundverordnung (DSGVO) ist die
                </p>
                <ul>
                  <li>Cyber Trust Services GmbH</li>
                  <li>Wienerbergstraße 11/12A A-1100 Wien</li>
                  <li>
                    Unseren Datenschutzbeauftragten erreichen Sie unter:{" "}
                    <a href="mailto:datenschutz@cyber-trust.at">
                      datenschutz@cyber-trust.at
                    </a>
                  </li>
                </ul>
                <div className="name">
                  I. Erhebung von Daten bei Nutzung unserer Webseite
                </div>
                <p>
                  Unsere Webseite nutzt die Zählpixeltechnologie der WiredMinds
                  GmbH (www.wiredminds.de) zur Analyse des Besucherverhaltens.
                  Hierbei wird die IP-Adresse eines Besuchers verarbeitet. Die
                  Verarbeitung erfolgt ausschließlich zu dem Zweck,
                  firmenrelevante Informationen wie z. B. den Unternehmensnamen
                  zu erheben. IP Adressen von natürlichen Personen werden von
                  der weiteren Verwendung ausgeschlossen (Whitelist-Verfahren).
                  Eine Speicherung der IP-Adresse in LeadLab erfolgt in keinem
                  Fall. Bei der Verarbeitung der Daten ist es unser besonderes
                  Interesse, die Datenschutzrechte natürlicher Personen zu
                  wahren. Unser Interesse stützt sich auf Art. 6 Abs. 1 lit. (f)
                  DSGVO. Die von uns erhobenen Daten lassen zu keiner Zeit einen
                  Rückschluss auf eine identifizierbare Person zu. Die
                  WiredMinds GmbH nutzt diese Informationen, um{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    anonyme
                  </span>{" "}
                  Nutzungsprofile, bezogen auf das Besuchsverhalten auf unserer
                  Webseite, zu erstellen. Die dabei gewonnenen Daten werden
                  nicht benutzt, um den Besucher unserer Webseite persönlich zu
                  identifizieren. Es werden weiters keinerlei Daten an
                  Werbeanbieter weitergegeben. Über den LinkedIn Insight Tag Manager 
                  erhalten wir die Information, wer von LinkedIn auf unsere Website kommt. 
                  Dabei werden ausschließlich Jobtitel und Firmenname übertragen, jedoch 
                  grundsätzlich keine Namen von Personen.
                </p>
                <ul>
                  <li>
                    <a onClick={handleReject}>WIDERSPRUCHSLINK (OPT-OUT)</a>
                  </li>
                </ul>
                <div className="name">
                  II. Datenerhebung und -verwendung bei Kontaktaufnahme und
                  Nutzung des Anforderungsformulars
                </div>
                <p>
                  Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über unser
                  Anforderungsformular werden die von Ihnen mitgeteilten Daten
                  (dies kann umfassen: Ihre E-Mail-Adresse, Ihr Name, Ihre
                  Telefonnummer bzw. weitere von ihnen gemachte Angaben) von uns
                  gespeichert, um Ihre Fragen beantworten zu können. Um Ihre
                  Labelanforderung bearbeiten zu können, erheben und speichern
                  wir folgende Daten: Vorname, Nachname, E-Mail-Adresse,
                  Telefonnummer, Unternehmen, Rolle, Branche und Adresse. Die
                  Rechtsgrundlage für diese Verarbeitung ist Vertragsanbahnung-
                  und -erfüllung gemäß Artikel 6 Abs 1 lit b DSGVO.
                </p>
                <div className="name">III. Datenlöschung und Speicherdauer</div>
                <p>
                  Die Daten werden nur so lange gespeichert, wie diese zur
                  Vertragserfüllung erforderlich sind. Danach werden die Daten
                  gelöscht. Es können darüber hinaus gesetzliche
                  Aufbewahrungspflichten bestehen zB nach Unternehmensgesetzbuch
                  (UGB) und Bundesabgabenordnung (BAO). Nach Ablauf der
                  gesetzlichen Aufbewahrungsfristen werden wir Ihre
                  personenbezogenen Daten unverzüglich aus unseren Datenbanken
                  (sowohl digital als auch physisch) löschen.
                </p>
                <div className="name">
                  IV. Übermittlung der Daten an Auftragsverarbeiter
                </div>
                <p>
                  Bei der Verarbeitung bedient sich CTS Dritter, insbesondere im
                  Bereich IT. Diese verarbeiten die Daten als sogenannte
                  Auftragsverarbeiter, d.h. auf Grundlage eines schriftlichen
                  Vertrags gemäß Artikel 28 DSGVO, in dem die Einzelheiten der
                  Datenverarbeitung im Auftrag der CTS geregelt sind und in dem
                  sich der Auftragsverarbeiter zum sorgfältigen Umgang mit den
                  Daten verpflichtet. Eine solche Auftragsverarbeitung liegt
                  beispielsweise vor, wenn CTS Daten in einem externen
                  Rechenzentrum speichert. Die Auftragsverarbeiter werden von
                  CTS unter besonderer Berücksichtigung der Eignung der von
                  ihnen getroffenen technischen und organisatorischen Maßnahmen
                  sorgfältig ausgewählt und auf deren Einhaltung überprüft. CTS
                  verarbeitet die Daten grundsätzlich in Österreich und in der
                  der Europäischen Union.
                </p>
                <div className="name">V. Datenweitergabe an Dritte</div>
                <p>
                  Wir geben keine personenbezogenen Daten an Dritte weiter, es
                  sei denn, dies ist für unsere legitimen geschäftlichen
                  Bedürfnisse sowie Vertragserfüllung erforderlich, und/oder
                  wenn dies gesetzlich oder durch berufliche Standards
                  vorgeschrieben oder erlaubt ist. Für die Erstellung des für
                  die Labelvergabe notwendigen Cyber Risk Ratings werden die
                  notwendigen Kontaktdaten an die Firma Nimbusec GmbH
                  weitergegeben. Dies ist für die Vertragserfüllung
                  erforderlich. Hierbei wird auch auf die Datenschutzerklärung
                  der Nimbusec GmbH verwiesen.
                </p>
                <div className="name">VI. Sicherheit der Datenverarbeitung</div>
                <p>
                  Wir bedienen uns geeigneter technischer und organisatorischer
                  Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder
                  vorsätzliche Manipulationen, teilweisen oder vollständigen
                  Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter
                  zu schützen (zB TLS-Verschlüsselung für unsere Webseite) unter
                  Berücksichtigung des Stands der Technik, der
                  Implementierungskosten und der Natur, des Umfangs, des
                  Kontextes und des Zwecks der Verarbeitung sowie der
                  bestehenden Risiken einer Datenpanne (inklusive von deren
                  Wahrscheinlichkeit und Auswirkungen) für den Betroffenen.
                  Unsere Sicherheitsmaßnahmen werden entsprechend der
                  technologischen Entwicklung fortlaufend verbessert.
                </p>
                <div className="name">VII. Ihre Rechte</div>
                <p>
                  Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
                  betreffenden personenbezogenen Daten:
                </p>
                <ul>
                  <li>Recht auf Auskunft,</li>
                  <li>Recht auf Berichtigung oder Löschung,</li>
                  <li>Recht auf Einschränkung der Verarbeitung,</li>
                  <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                  <li>Recht auf Datenübertragbarkeit.</li>
                </ul>
                <p>
                  Des Weiteren haben Sie auch das Recht, Beschwerde bei der
                  zuständigen Aufsichtsbehörde (in Österreich die
                  Datenschutzbehörde mit Sitz in Wien) zu erheben. Die
                  Datenschutzbehörde ist unter der folgenden Adresse erreichbar:
                </p>
              </div>
              <div className="address">
                <ul>
                  <li>Österreichische Datenschutzbehörde</li>
                  <li>Barichgasse 40-42 1030 Wien</li>
                  <li>Telefon: +43 1 52 152-0</li>
                  <li>
                    E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a>
                  </li>
                </ul>
              </div>
              <div className="text">
                <div className="name">
                  VIII. Aktualisierung der Datenschutzerklärung
                </div>
                <p>
                  Wir behalten uns das Recht vor, jederzeit Änderungen an dieser
                  Datenschutzerklärung vorzunehmen. Die Datenschutzerklärung
                  wird regelmäßig aktualisiert und alle Änderungen werden
                  automatisch veröffentlicht.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {/* Bottom-right Scroll-UP button */}
      {/* <a
        className="main-button__top js-scroll-top"
        style={{ opacity: "1" }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span></span>
        <svg
          preserveAspectRatio="none"
          className="progress"
          viewBox="0 0 110 110"
        >
          <circle className="circle-bg" r="50" cx="55" cy="55"></circle>
          <circle
            className="circle-go"
            r="50"
            cx="55"
            cy="55"
            style={{
              strokeDasharray: "314.159px",
              strokeDashoffset: "119.68px",
            }}
          ></circle>
        </svg>
      </a> */}
    </React.Fragment>
  )
}

export default Datenschutzerklaerung
